const objectItem = value => {
    return {
        value,
        label: value,
    };
};
const useValueSelect = value => {
    if (!value) return;
    const isArray = Array.isArray(value);

    if (isArray && value.length >= 0) {
        return value.map(item => objectItem(item));
    }
    return objectItem(value);

};

export default { useValueSelect, objectItem };